import { React, useRef } from 'react';

/** Utils */
import { generateBem } from '../../../utils/generateBem';

import './Topbar.scss';
import { Button } from '../';
import { useLocation as location } from 'react-router';
import { Link, NavLink } from 'react-router-dom';

/** assets */
import logo from '../../../assets/images/paperOS_wordmark_white_lg.png';

const bem = generateBem('Topbar');
const Topbar = ({ isPlans }) => {
  const { search } = location();
  const placement = useRef();

  return (
    <div className={bem('dropdown--wrapper')} ref={placement}>
      <header className="Topbar">
        <div className={`container ${bem('wrapper')}`}>
          <Link to={{ pathname: '/Capital', search }}>
            <img
              src={logo}
              className={bem('companyLogo')}
              alt="savvi legal desktop logo"
            />
          </Link>
          <div className={bem('menu')}>
            <NavLink
              to="/Capital"
              className={bem('pricingLink')}
              activeClassName={bem('pricingLink--active')}
            >
              Private Capital
            </NavLink>
            <NavLink
              to="/Founders"
              className={bem('pricingLink')}
              activeClassName={bem('pricingLink--active')}
            >
              Founders
            </NavLink>
            <NavLink
              to="/Funds"
              className={bem('pricingLink')}
              activeClassName={bem('pricingLink--active')}
            >
              Funds
            </NavLink>
            {/* <NavLink
              to="/practice"
              className={bem('pricingLink')}
              activeClassName={bem('pricingLink--active')}
            >
              Practice
            </NavLink> */}
            <NavLink
              to={{ pathname: '/plans', search }}
              className={bem(['pricingLink', isPlans ? 'pricingLink--optional' : null])}
              activeClassName={bem('pricingLink--active')}
            >
              Platform Plans
            </NavLink>
          </div>
          <div className={bem('actions')}>
            <Button isFlat href={`https://app.paperos.com/login${search}`} size="sm">
              Sign In
            </Button>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Topbar;
